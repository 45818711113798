<template>
    <div class="">
        <div>
      <b-navbar toggleable="lg" type="light">
        <b-container>
          <b-navbar-brand to="/login"
            ><img
              src="@/assets/images/pages/MyPals_Logo_Dark.svg"
              width="auto"
              height="44"
              alt="MyPals"
          /></b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <b-nav-item class="mr-2" to="/login">Home</b-nav-item>
              <!-- <b-nav-item class="mr-2" to="/howitworks"
                >How It Works</b-nav-item
              > -->

           <!--    <b-button
                class="login-button"
                variant="primary"
                href="javascript:void(0);"
                @click="showLogin"
                data-toggle="modal"
                data-target="#loginModal"
                >Log In</b-button
              >  -->
              
            </b-navbar-nav>
          </b-collapse>
        </b-container>
      </b-navbar>
    </div>

    <section class="privacy-policy bg-white">
        <div class="container">
            <div class="row justify-content-center text-center">
          <div class="col-lg-10 section-heading">
            <h2>
             Privacy Policy
            </h2>

          </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="mb-5">
                </div>
                <div class="pp-cont">
                    <h5>We respect your privacy</h5>
                    <ul>
                        <li>BRIKS INFOTECH AUSTRALIA PTY LTD respects your right to privacy and is committed to safeguarding the privacy of our customers and website visitors. We adhere to the Australian Privacy Principles contained in the Privacy Act 1988 (Cth). This policy sets out how we collect and treat your personal information.</li>
<li>"Personal information" is information we hold which is identifiable as being about you.</li>
                    </ul>
                </div>

                <div class="pp-cont">
                    <h5>Collection of personal information</h5>
                    <ul>
                        <li>BRIKS INFOTECH AUSTRALIA PTY LTD will, from time to
time, receive and store personal information you enter onto our website,
provided to us directly or given to us in other forms.
</li>
<li>You may provide basic information such as your name, phone number, address
and email address to enable us to send information, provide updates and
process your product or service order. We may collect additional information at
other times, including but not limited to, when you provide feedback, when you
provide information about your personal or business affairs, change your content
or email preference, respond to surveys and/or promotions, provide financial or
credit card information, or communicate with our customer support.</li>
<li>Additionally, we may also collect any other information you provide while
interacting with us.
</li>
                    </ul>
                </div>
                <div class="pp-cont">
                    <h5>How we collect your personal information</h5>
                    <ul>
                        <li>BRIKS INFOTECH AUSTRALIA PTY LTD collects personal
information from you in a variety of ways, including when you interact with us
electronically or in person, when you access our website and when we provide
our services to you. We may receive personal information from third parties. If
we do, we will protect it as set out in this Privacy Policy.
</li>
                    </ul>
                </div>
                <div class="pp-cont">
                    <h5>Use of your personal information</h5>
                    <ul>
                        <li>BRIKS INFOTECH AUSTRALIA PTY LTD may use personal
information collected from you to provide you with information, updates and our
services. We may also make you aware of new and additional products, services
and opportunities available to you. We may use your personal information to
improve our products and services and better understand your needs.
</li>
<li>BRIKS INFOTECH AUSTRALIA PTY LTD may contact you by
a variety of measures including, but not limited to telephone, email, sms or mail.</li>
                    </ul>
                </div>
                <div class="pp-cont">
                    <h5>Disclosure of your personal information</h5>
                    <ul>
                        <li>We may disclose your personal information to any of our employees, officers,
insurers, professional advisers, agents, suppliers or subcontractors insofar as
reasonably necessary for the purposes set out in this Policy. Personal
information is only supplied to a third party when it is required for the delivery of
our services.</li>
<li>We may from time to time need to disclose personal information to comply with a
legal requirement, such as a law, regulation, court order, subpoena, warrant, in
the course of a legal proceeding or in response to a law enforcement agency
request.</li>
<li>We may also use your personal information to protect the copyright, trademarks,
legal rights, property or safety of BRIKS INFOTECH AUSTRALIA PTY LTD, https://mypals.app/, its customers or third parties.
</li>
<li>If there is a change of control in our business or a sale or transfer of business
assets, we reserve the right to transfer to the extent permissible at law our user
databases, together with any personal information and non-personal information
contained in those databases. This information may be disclosed to a potential
purchaser under an agreement to maintain confidentiality. We would seek to only
disclose information in good faith and where required by any of the above
circumstances.
</li>
<li>By providing us with personal information, you consent to the terms of this
Privacy Policy and the types of disclosure covered by this Policy. Where we
disclose your personal information to third parties, we will request that the third
party follow this Policy regarding handling your personal information.
</li>
                    </ul>
                </div>
                <div class="pp-cont">
                    <h5>Security of your personal information</h5>
                    <ul>
                        <li>BRIKS INFOTECH AUSTRALIA PTY LTD is committed to ensuring that the information you provide to us is secure. In order to prevent
unauthorised access or disclosure, we have put in place suitable physical,
electronic and managerial procedures to safeguard and secure information and
protect it from misuse, interference, loss and unauthorised access, modification
and disclosure.</li>
<li>The transmission and exchange of information is carried out at your own risk.
We cannot guarantee the security of any information that you transmit to us, or
receive from us. Although we take measures to safeguard against unauthorised
disclosures of information, we cannot assure you that personal information that
we collect will not be disclosed in a manner that is inconsistent with this Privacy
Policy.
</li>
                    </ul>
                </div>
                <div class="pp-cont">
                    <h5>Access to your personal information</h5>
                    <ul>
                        <li>You may request details of personal information that we hold about you in
accordance with the provisions of the Privacy Act 1988 (Cth). A small
administrative fee may be payable for the provision of information. If you would
like a copy of the information, which we hold about you or believe that any
information we hold on you is inaccurate, out of date, incomplete, irrelevant or
misleading, please email us at hello@mypals.app.
</li>
<li>We reserve the right to refuse to provide you with information that we hold about
you, in certain circumstances set out in the Privacy Act.
</li>
                    </ul>
                </div>
                <div class="pp-cont">
                    <h5>Complaints about privacy</h5>
                    <ul>
                        <li>If you have any complaints about our privacy practises, please feel free to send
in details of your complaints to Level 23, Collins Square Tower Five, 727 Collins
St, Melbourne, Victoria, 3008. We take complaints very seriously and will
respond shortly after receiving written notice of your complaint.
</li>
                    </ul>
                </div>
                <div class="pp-cont">
                    <h5>Changes to Privacy Policy</h5>
                    <ul>
                        <li>Please be aware that we may change this Privacy Policy in the future. We may
modify this Policy at any time, in our sole discretion and all modifications will be
effective immediately upon our posting of the modifications on our website or
notice board. Please check back from time to time to review our Privacy Policy.
</li>
                    </ul>
                </div>
                <div class="pp-cont">
                    <h5>Website</h5>
                    <ul>
                        <li><em>When you visit our website</em> <br>you come to our website (https://mypals.app/) we may collect certain
information such as browser type, operating system, website visited immediately
before coming to our site, etc. This information is used in an aggregated manner
to analyse how people use our site, such that we can improve our service.
</li>
<li><em>Cookies</em> <br>We may from time to time use cookies on our website. Cookies are very small
files which a website uses to identify you when you come back to the site and to
store details about your use of the site. Cookies are not malicious programs that
access or damage your computer. Most web browsers automatically accept
cookies but you can choose to reject cookies by changing your browser settings.
However, this may prevent you from taking full advantage of our website. Our
website may from time to time use cookies to analyses website traffic and help
us provide a better website visitor experience. In addition, cookies may be used
to serve relevant ads to website visitors through third party services such as
Google Adwords. These ads may appear on this website or other websites you
visit.
</li>
<li><em>Third party sites</em> <br>Our site may from time to time have links to other websites not owned or
controlled by us. These links are meant for your convenience only. Links to third
party websites do not constitute sponsorship or endorsement or approval of
these websites. Please be aware that BRIKS INFOTECH AUSTRALIA PTY is not responsible for the privacy practises of other such
websites. We encourage our users to be aware, when they leave our website, to
read the privacy statements of each and every website that collects personal
identifiable information.
</li>
                    </ul>
                </div>
            </div>
        </div>

        </div>
    </section>


    <footer class="footer-pages border-top">
      <div class="container">
        <div class="row" style="padding-bottom: 64px">
          <div class="col-lg-5 mb-5 mb-md-0 mb-lg-0">
            <div class="footer-info">
              <img
                src="@/assets/images/pages/MyPals_Logo_Dark.svg"
                width="auto"
                height="44"
                alt="MyPals"
              />
              <p>
               MyPals is a social media to exchange digital arts by connecting creators and buyers together.
              </p>
            </div>
          </div>
          <div class="col-lg-3 mb-5 mb-md-0 mb-lg-0">
            <div class="footer-links">
              <h5>Helpful Links</h5>
              <ul class="list-unstyled">
                <li class="d-flex mb-2">
                  <span class="material-icons">chevron_right</span>
                  <router-link to="/login">
                    <a class="" href="#">Home</a>
                  </router-link>
                </li>
                <li class="d-flex mb-2">
                  <span class="material-icons">chevron_right</span>
                  <a class="" href="/howitworks">How It Works</a>
                </li>

                <li class="d-flex mb-2">
                    <span class="material-icons">chevron_right</span>
                <a class="" href="/privacy-policy">Privacy policy</a>
                </li>
                <!-- <li class="d-flex">
                    <span class="material-icons">chevron_right</span>
                <a class="" href="#">Contact us</a>
                </li> -->
              </ul>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="footer-contact">
              <h5>Contact Us</h5>
             <!--     <span class="mb-2">Phone: +61 0424910822</span>    -->
              <p>
                Email:
                <a class="" href="mailto:hello@mypals.app"
                  >hhello@mypals.app</a
                >
              </p>
             <!-- <ul class="list-inline social-media">
                <li class="list-inline-item mr-3">
                  <a class="" href="https://www.facebook.com/MyPals"
                    ><i class="fa fa-facebook" aria-hidden="true"></i
                  ></a>
                </li>
               <li class="list-inline-item mr-3">
                            <a class="" href="https://MyPals.com.au/index.php/linkedin.com/company/MyPals"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                         </li> 
                <li class="list-inline-item">
                  <a class="" href="https://wa.me/61424910822" target="_blank"
                    ><i class="fa fa-whatsapp" aria-hidden="true"></i
                  ></a>
                </li>
              </ul>  -->
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 text-center border-top">
            <div class="copyright-pages">
              <span class="text-small"
                >© 2023 MyPals. All Rights Reserved. Powered by
                <a href="#">BRiKS Technology Inc. </a></span
              >
            </div>
          </div>
        </div>
      </div>
    </footer>


    <!--Login Modal -->
    <b-modal id="loginModal" title hide-footer>
      <div class="modal-body">
        <div class="login-head text-center mb-4">
          <h3>Welcome to MyPals</h3>
          <p>Please log in to your account.</p>
        </div>
        <form>
          <div class="form-group">
            <vs-input
              v-validate="'required|email|min:3'"
              data-vv-validate-on="blur"
              name="email"
              icon-no-border
              icon="icon icon-user"
              icon-pack="feather"
              label="Email"
              v-model="email"
              class="w-full"
            />
            <span class="text-danger text-sm">{{ errors.first("email") }}</span>

          </div>
          <div class="form-group">
            <vs-input
              data-vv-validate-on="blur"
              v-validate="'required|min:6|max:30'"
              type="password"
              name="password"
              icon-no-border
              icon="icon icon-lock"
              icon-pack="feather"
              label="Password"
              v-model="password"
              class="w-full mt-6"
            />
            <span class="text-danger text-sm">{{
              errors.first("password")
            }}</span>
          </div>

          <div class="form-group">
            <div class="d-flex justify-content-between">
              <div class="form-group form-check d-flex">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="checkbox_remember_me"
                />
                <label class="form-check-label text-small" for="rememeber"
                  >Remember Me</label
                >
              </div>
              <router-link to="/forgot-password"
                >Forgot Password?</router-link
              >
            </div>
          </div>

          <div class="form-group">
            <button
              type="button"
              :disabled="!validateForm"
              @click="login"
              class="btn btn-lg btn-primary btn-block"
            >
              Log In
            </button>
          </div>

          <div class="form-group">
            <p class="text-center">
              Don't have an account?
              <a
                href="javascript:void(0);"
                @click="showSignup"
                data-toggle="modal"
                data-target="#signupModal"
                >Sign up</a
              >
            </p>
          </div>
        </form>
      </div>
    </b-modal>
    <!--End Login Modal -->

    <!--SignUp Modal -->
    <b-modal id="signupModal" title hide-footer>
      <div class="modal-body">
        <div class="login-head text-center mb-4">
          <h3>Create Account</h3>
          <p>Fill the form below to create a new account.</p>
        </div>
        <form>
          <div class="form-row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <!-- <label for="">Name</label> -->
                <input
                  v-validate="'required|min:3'"
                  data-vv-validate-on="blur"
                  label="Name"
                  name="displayName"
                  placeholder="Name"
                  v-model="displayName"
                  @change="usernameChange()"
                  class="form-control"
                  id=""
                />
                <span class="text-danger text-sm">{{
                  errors.first("displayName")
                }}</span>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="form-group">
                <!-- <label for="">Email</label> -->
                <input
                  v-validate="'required|email'"
                  data-vv-validate-on="blur"
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="Email"
                  v-model="registeration_email"
                  class="form-control"
                  id=""
                />
                <span class="text-danger text-sm">{{
                  errors.first("email")
                }}</span>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <!-- <label for="">Your University</label> -->
                <select
                  name="university"
                  label="Your University"
                  placeholder="University"
                  v-model="university"
                  class="form-control"
                >
                  <option value="">University</option>
                  <option
                    :key="item.university_id"
                    v-for="item in universityList"
                    v-bind:value="item.university_id"
                  >
                    {{ item.univ_name }}
                  </option>
                </select>
                <span class="text-danger text-sm">{{
                  errors.first("university")
                }}</span>
              </div>
            </div>

            <div class="col">
              <div class="form-group">
                <!-- <label for="">Your Study Area</label> -->
                <!-- <select class="form-control">
    <option>Study Area</option>
    </select> -->
                <select
                  name="studyArea"
                  label="Your Study Area"
                  placeholder="Study Area"
                  v-model="studyArea"
                  class="form-control"
                >
                  <option value="">Study Area</option>
                  <option
                    :key="item.stu_area_id"
                    v-for="item in studyAreaList"
                    v-bind:value="item.stu_area_id"
                  >
                    {{ item.study_area }}
                  </option>
                </select>
                <span class="text-danger text-sm">{{
                  errors.first("studyArea")
                }}</span>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col">
              <div class="form-group">

                <select
                  name="course"
                  label="Course Studying or Studied"
                  placeholder="Please select university and study area"
                  v-model="course"
                  class="form-control"
                >
                  <option value="">Course</option>
                  <option
                    :key="item.course_id"
                    v-for="item in courseList"
                    v-bind:value="item.course_id"
                  >
                    {{ item.course_name }}
                  </option>
                </select>
                <span class="text-danger text-sm">{{
                  errors.first("course")
                }}</span>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <!-- <label for="">Currently studying or alumni ?</label> -->
                <!-- <select class="form-control">
    <option>Course Studying or Studied</option>
    </select> -->
                <select
                  name="studyStatus"
                  v-model="studyStatus"
                  label="Currently studying or alumni ?"
                  placeholder="Course Studying or Studied"
                  class="form-control"
                >
                  <option value="">Currently studying or alumni ?</option>
                  <option
                    :key="item.value"
                    v-for="item in studyStatusSelect"
                    v-bind:value="item.value"
                  >
                    {{ item.value }}
                  </option>
                </select>
                <span class="text-danger text-sm">{{
                  errors.first("studyStatus")
                }}</span>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <!-- <label for="">Password</label> -->
                <input
                  ref="password"
                  type="password"
                  data-vv-validate-on="blur"
                  v-validate="'required|min:6|max:32'"
                  name="password"
                  label="Password"
                  placeholder="Password"
                  v-model="registeration_password"
                  class="form-control"
                />
                <span class="text-danger text-sm">{{
                  errors.first("password")
                }}</span>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="form-group">
                <!-- <label for="">Confirm Password</label> -->
                <input
                  type="password"
                  v-validate="'min:6|max:32|confirmed:password'"
                  data-vv-validate-on="blur"
                  data-vv-as="password"
                  name="confirm_password"
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  v-model="confirm_password"
                  class="form-control"
                />
                <span class="text-danger text-sm">{{
                  errors.first("confirm_password")
                }}</span>
              </div>
            </div>
          </div>

          <div class="form-group form-check mb-4">
            <input
              v-model="isTermsConditionAccepted"
              type="checkbox"
              class="form-check-input"
            />
            <label class="form-check-label text-small" for="rememeber"
              >I accept the <a href="/privacy-policy" target="_blank">Privacy policy</a></label
            >
          </div>

          <div class="form-group">
            <button
              type="button"
              @click="registerUser"
              :disabled="!validateRegisterForm"
              class="btn btn-lg btn-primary btn-block"
            >
              Sign up
            </button>
          </div>
          <div class="form-group">
            <p class="text-center">
              Already have account?
              <a
                href="#"
                @click="showLogin"
                data-toggle="modal"
                data-target="#loginModal"
                >Log in</a
              >
            </p>
          </div>
        </form>
      </div>
    </b-modal>
    <!-- End SignUp Modal -->


    </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      email: '',
      registeration_email: '',
      password: '',
      registeration_password: '',
      checkbox_remember_me: false,
      displayName: '',
      university: '',
      studyArea: '',
      course: '',
      studyStatus: '',
      interestedChannels: [],
      confirm_password: '',
      isTermsConditionAccepted: true,
      universityList: [],
      studyAreaList: [],
      courseList: [],
      studyStatusSelect: [
        { value: 'Student' },
        { value: 'Alumni' },
        { value: 'Future Student' }
      ],
      interestedChannelList: []
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.email !== '' && this.password !== ''
    },
    validateRegisterForm () {
      return (
        !this.errors.any() &&
        this.displayName !== '' &&
        this.registeration_email !== '' &&
        this.registeration_password !== '' &&
        this.confirm_password !== '' &&
        this.isTermsConditionAccepted === true
      )
    }
  },
  created () {
    axios
      .post(`${process.env.VUE_APP_BASE_URL  }/getinstitutelist`)
      .then((res) => {
        this.universityList = res.data.data
      })
      .catch((err) => this.$store.commit('SET_ERROR', err, { root: true }))
    axios
      .post(`${process.env.VUE_APP_BASE_URL  }/getAllChannels`)
      .then((res) => {
        this.interestedChannelList = res.data.data
      })
      .catch((err) => this.$store.commit('SET_ERROR', err, { root: true }))
  },
  watch: {
    university (newUniversity) {
      // fetch study area list
      if (newUniversity !== '') {
        axios
          .post(`${process.env.VUE_APP_BASE_URL  }/StudyAreasofAnUniversity`, {
            univ_id: newUniversity
          })
          .then((res) => {
            this.studyAreaList = res.data.data
          })
          .catch((err) => this.$store.commit('SET_ERROR', err, { root: true }))
      }
    },
    studyArea (newStudyArea) {
      if (this.university !== '' && newStudyArea !== '') {
        this.getCourseList(this.university, newStudyArea)
      }
    }
  },
  methods: {
    usernameChange(){
      this.username = this.username.split(' ').join('_');
    },
    showSignup () {
      this.displayName = ''
      this.registeration_email = ''
      this.university = ''
      this.studyArea = ''
      this.course = ''
      this.studyStatus = ''
      this.interestedChannels = ''
      this.registeration_password = ''
      this.confirm_password = ''
      this.studyAreaList = []
      this.courseList = []
      this.$bvModal.hide('loginModal')
      this.$bvModal.show('signupModal')
    },
    showLogin () {
      this.email = ''
      this.password = ''
      this.$bvModal.hide('signupModal')
      this.$bvModal.show('loginModal')
    },
    checkLogin () {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })

        return false
      }
      return true
    },
    login () {
      // Loading
      this.$vs.loading()

      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          email: this.email.toLowerCase(),
          password: this.password
        },
        notify: this.$vs.notify,
        closeAnimation: this.$vs.loading.close
      }
      this.$store.dispatch('auth/loginAttempt', payload)
    },
    registerUser () {
      // If form is not validated or user is already login return
      if (!this.validateRegisterForm || !this.checkLogin()) return

      if (!/@MyPals.app\s*$/.test(this.registeration_email)) {
        this.$vs.notify({
          title: 'Failed',
          text: 'Please use your university email address to signup!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return false
      }

      const payload = {
        userDetails: {
          displayName: this.displayName,
          email: this.registeration_email.toLowerCase(),
          university: this.university,
          studyArea: this.studyArea,
          course: this.course,
          studyStatus: this.studyStatus,
          interestedChannels: this.interestedChannels,
          password: this.registeration_password,
          confirmPassword: this.confirm_password
        },
        notify: this.$vs.notify
      }
      this.$store.dispatch('auth/registerUser', payload)
    },
    getCourseList (universityId, studyAreaId) {
      axios
        .post(`${process.env.VUE_APP_BASE_URL  }/getCoursesBasedOnUniAndStudyArea`, {
          univ_id: universityId,
          study_area: studyAreaId
        })
        .then((res) => {
          if (res.data.status) {
            this.courseList = res.data.data
          }
        })
    }
  }
}
</script>

<style scoped lang="scss" >
body {
  font-family: "Open Sans", sans-serif !important;
  color: #626262;
  font-size: 16px !important;
  background-color: #fff !important;
}
p {
  font-size: 16px !important;
  margin-bottom: 1rem !important;
}
span {
  font-size: 16px !important;
}
.text-small {
  font-size: 14px !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #515365;
  /* color:#282828; */
  font-weight: 600;
}
h4,
h6 {
  margin-bottom: 0.5rem;
}
.lead {
  font-size: 20px !important;
}
.pp-cont {
    margin-bottom: 24px;
}
.pp-cont ul {
    margin-top: 0;
    margin-bottom: 1rem;
}
.pp-cont ul {
    list-style-type: disc;
}
.pp-cont li{
    margin-bottom: 14px;
    margin-left: 16px;
}
</style>